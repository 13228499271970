.eventManagement-form-box[data-v-e09bacbe] {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
}
.eventManagement-form-box .form-left[data-v-e09bacbe] {
  background-color: #ffffff;
  width: 125px;
  margin-right: 10px;
}
.eventManagement-form-box .form-left .el-step.is-vertical[data-v-e09bacbe] {
  width: 99px;
}
.eventManagement-form-box .form-left .flex-container[data-v-e09bacbe] {
  padding: 12px;
}
.eventManagement-form-box .form-right[data-v-e09bacbe] {
  width: calc(100% - 135px);
  background-color: #ffffff;
}
.eventManagement-form-box .form-right .el-form[data-v-e09bacbe] {
  padding: 0 12px;
}
.eventManagement-form-box .form-right .el-collapse-item[data-v-e09bacbe] {
  padding: 0 20px;
}
.eventManagement-form-box .el-image[data-v-e09bacbe] {
  margin-right: 10px;
}
.eventManagement-form-box .el-collapse-item__content[data-v-e09bacbe] {
  padding: 0 20px;
}
.eventManagement-form-box .el-collapse-item__header[data-v-e09bacbe] {
  padding-left: 20px;
}
.eventManagement-form-box .lossIndirect[data-v-e09bacbe] {
  display: inline-block;
  color: #909399;
  vertical-align: middle;
  position: relative;
  border-radius: 4px;
  padding: 0 10px;
  white-space: nowrap;
}
.eventManagement-form-box .content[data-v-e09bacbe] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.eventManagement-form-box .content .img-content[data-v-e09bacbe] {
  width: 188px;
  height: 100px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.eventManagement-form-box .content .img-content .delete-icon[data-v-e09bacbe] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.eventManagement-form-box .content .img-content img[data-v-e09bacbe] {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}